body,
html {
    height: 100%;
    font-family: Roboto, "Helvetica Neue",Helvetica,Arial,sans-serif;
}
#bloomberg-container.main-container{
    padding-top: 0;
}
.main-container{
    padding-top: 30px;
    padding-bottom: 60px;
}
.main-container:after {
    content: '';
    display: block;
    clear: both;
    visibility: hidden;
}
body {
    min-width: 320px;
}
.global-search-input{
    color: #fff;
}
.search-input::-webkit-input-placeholder {
   font-style: normal;
   font-weight: bold;
    color: #fff;
    text-transform: uppercase;
}
.search-input:-moz-placeholder {
   font-style: normal;
   font-weight: bold;
   color: #fff;
   text-transform: uppercase;
}
.search-input::-moz-placeholder {
   font-style: normal;
   font-weight: bold;
    color: #fff;
    text-transform: uppercase;
}
.search-input:-ms-input-placeholder {
   font-style: normal;
   font-weight: bold;
   color: #fff;
   text-transform: uppercase;
}
rect.resize-rhomb {
    fill: #64B5F6;
    height: 10px;
}

.loading {
    width:100%;
    height:100%;
    position: absolute;
    border-radius: 5px;
    z-index: 1001;
    display: none;
}

.loading .back {
    width:100%;
    height:100%;
    top:0px;
    bottom:0px;
    position: absolute;
    background-color: #333333;
    /* opacity: 0.8; */
    border-radius: 5px;
}

.loading .spinner{
    position: absolute;
    /* background-image: url('http://www.silversea.com/wp-content/themes/silversea/images/loader.gif'); */
    /* background-image: url('//www.silversea.com/wp-content/themes/silversea/images/loader.gif'); */
    background-size: cover;
    margin-top: -50px;
    margin-left: -50px;
    height: 100px;
    width: 100px;
    top: 40%;
    left: 50%;
}

.chart-title {
    font-size: 14px;
    font-weight: bold;
}

.brush-text {
    font-size: 14px;
    font-weight: bold;
    fill: #64B5F6;
    opacity: 0.6;
}

.text-rhomb {
    fill: #64B5F6;
    font-size: 11px;
    font-weight: bold;
}

.image-auto-resize {
    max-width:100%;
    max-height:100%;
}
.global-question-block{
    color: #0400ff;
    cursor: pointer;
    font-size: 13px;
    background: #fff;
    border-radius: 50%;
    height: 10px;
    display: inline-block;
    width: 10px;
    margin-left: 4px;
    vertical-align: top;
}
.global-question-block > i{
    display: block;
    margin: -1px 0 0;
    font-size: 13px!important;
}
.global-answer-block{
    position: absolute;
    background: #fff;
    color: #333;
    font-size: 12px;
    width: 300px;
    display: block;
    white-space: normal;
    border: 1px solid rgba(0,0,0,0.4);
    line-height: 1.4;
    z-index: 2000;
}
.global-answer-block > span{
    padding: 5px;
    display: block;
}
.top-data .global-question-block[data-key='2']{
    left: 25px;
}
.top-data .global-question-block{
    position: absolute;
    z-index: 11;
    left: 0;
    top: 0;
}
.top-data .toggle-visibility .global-question-block,
.top-data .factors-legend-title .global-question-block{
    position: static;
    vertical-align: baseline;
}
.performance-header .global-question-block{
    position: absolute;
    z-index: 2;
    top: 4px;
    right: 3px;
    -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
            transform: rotate(90deg);
}
.performance-header .global-question-block > i{
    line-height: 1!important;
}
/*top menu style*/
#bloomberg-container .top-menu{
    margin: 0;
}
.top-menu{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
    margin-left:45px;
}
#bloomberg-container .top-menu > li{
    margin: 5px 10px;
}
.top-menu > li{
    margin: 5px 20px;
    position: relative;
    z-index: 10;
    font-size: 11px;
    line-height: 20px;
}
.top-menu > li .sub-menu-container{
    position: absolute;
    z-index: 1;
    left: 0;
    top: 20px;
    color: #333;
    width: 0;
    overflow: hidden;
}
.top-menu > li .sub-menu-container .portfolio-sub-menu {
    display: none;
}
.top-menu > li .portfolio-sub-menu-block:hover .portfolio-sub-menu {
    display: block;
}
.top-menu > li .show-more-btn {
    position: relative;
    width: 100%;
    text-align: center;
}

.top-menu > li .sub-menu-block,
.globalMenu-item-mob .sub-menu-block{
    background: #fff;
    -webkit-box-shadow: 2px 2px 5px rgba(0,0,0, 0.1);
            box-shadow: 2px 2px 5px rgba(0,0,0, 0.1);
}
.top-menu > li .sub-menu-container .stock-item{
    display: block;
    margin: 0;
}
.top-menu > li > span{
    font-weight: bold;
}
.globalMenu-item-mob .sub-menu-block .stock-item__btn,
.top-menu > li .sub-menu-container .stock-item__btn{
    width: 100%!important;
    height: auto;
    padding: 3px 10px;
    background: transparent;
    border: 0;
    display: block;
    color: #005594;
    text-transform: none;
    font-size: 11px!important;
    white-space: nowrap;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    text-decoration: none;
    font-weight: normal;
    text-align: left;
    text-transform: uppercase;
}
.top-menu > li .sub-menu-container  .stock-item__btn:hover,
.top-menu > li .sub-menu-container  .stock-item__btn.active-stock{
    color: #005594;
    background: #eee!important;
    font-weight: bold;
}
.top-menu > li .sub-menu-container  .stock-item__btn.stock-item-dynamic {
    font-weight: bold;
}
.top-menu > li > a{
    color: #fff;
    display: inline-block;
    text-decoration: none;
    position: relative;
    z-index: 1;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 11px;
}
.top-menu > li > span{
    text-transform: uppercase;
}
.top-menu > li.active > a:before,
.top-menu > li.active > span:before{
    content: "";
    height: 2px;
    bottom: 0;
    left: 0;
    width: 100%;
    display: block;
    background: #ccc;
    position: absolute;
}
.top-menu > li > a:before{
    width: 0;
}
.top-menu > li:hover > a:before{
    width: 100%;
    -webkit-transition: width 0.2s;
    -o-transition: width 0.2s;
    transition: width 0.2s;
}
.top-menu > li .sub-menu-container{
    min-width: 130px;
    overflow: hidden;
    height: 0;
    -webkit-box-shadow: 2px 2px 5px rgba(0,0,0,.3);
            box-shadow: 2px 2px 5px rgba(0,0,0,.3);
}
.top-menu > li:hover .sub-menu-container{
    height: auto;
}
.top-menu > li[data-name='countries'] .sub-menu-container{
   width: 140px;
}
.top-menu > li[data-name='technicals'] .sub-menu-container{
    width: auto;
}
.top-menu > li[data-name='portfolio'] .sub-menu-container,
.top-menu > li[data-name='themes'] .sub-menu-container {
   width: 220px;
}
.top-menu > li .sub-menu-container .sub-menu-block{
    width: 100%;
    -webkit-transform: translate(-100%, 0);
        -ms-transform: translate(-100%, 0);
            transform: translate(-100%, 0);
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
}
.top-menu > li:hover .sub-menu-container .sub-menu-block{
    -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
            transform: translate(0, 0);
}
.top-menu > li[data-name='countries'] .stock-menu.country-menu,
.top-menu > li[data-name='portfolio'] .sub-menu-block,
.top-menu > li[data-name='themes'] .sub-menu-block {
    display: block;
    max-height: 500px;
    overflow: auto;
}

.top-menu > li .stock-item input{
    display: none;
}
.stock-item-menu-title span{
    color: #005594;
    font-size: 13px;
    padding-left: 10px;
    font-weight: bold;
    text-transform: uppercase;
    display: block;
    padding: 5px 10px;
    cursor: pointer;
}
.stock-item-menu-title span:after{
    content: ":";
}
.stock-item-menu-title{
    position: relative;
    z-index: 1;
}
.stock-item-menu-title .stock-items-parent{
    display: none;
    position: absolute;
    z-index: 1;
    left: 100%;
    top: -1px;
    background: #fff;
    border: 1px solid rgba(0,0,0,.1);
    border-bottom: 0;
}
.stock-item-menu-title:hover span{
    background: #eee;
}
.stock-item-menu-title:hover .stock-items-parent{
    display: block;
    width: 155px;
}
.top-menu>li:hover .sub-menu-container{
    overflow: visible;
}
.topmenu-parent {
    position: fixed;
    top:0;
    left:0;
    right: 0;
    background: #00365E;
    z-index: 900;
    color: #fff;
    -webkit-box-shadow: 0 0 2px rgba(0,0,0,0.3);
            box-shadow: 0 0 2px rgba(0,0,0,0.3);
    max-height: 500px;
}
#toggle-menu-button{
    position: fixed;
    z-index: 2000;
    /* left: 3px; */
    top: -4px;
    background: 0 0;
    color: #fff;
    outline: 0;
    padding: 6px 13px 5px;
    -webkit-box-shadow: none;
            box-shadow: none;
    font-size: 20px;
    line-height: 0;
}
#toggle-menu-button .close{
    display: none;
    color: #333;
    opacity: 1;
    font-weight: normal;
    text-shadow: none;
    font-size: 40px;
}
#toggle-menu-button.active .close{
    display: block;
}
#toggle-menu-button.active i{
    display: none;
}
/*.sidebar{
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 35px!important;
    background: #fff!important;
}
.sidebar:before{
    content: "";
    height: 100%;
    width: 40%;
    left: 60%;
    top: 0;
    background: #005594;
    position: fixed;
    z-index: 5;
    display: block;
}*/
.sidebar{
    top: 30px;
    bottom: auto;
    position: absolute!important;
    left: 0;
    width: auto;

    padding: 0px!important;
    background: #fff!important;

    overflow: visible;
}
.sidebar .nav-sidebar{
    margin: 0;
    font-size: 14px;
    text-transform: uppercase;
    background: #fff;
    -webkit-box-shadow: 2px 2px 5px rgba(0,0,0,.1);
            box-shadow: 2px 2px 5px rgba(0,0,0,.1);
    border: 1px solid #eee;
}
/* .nav-sidebar-client is not used */
/* .sidebar .nav-sidebar.nav-sidebar-client{
    background: transparent;
    border: 0;
    box-shadow: none;
}
.sidebar .nav-sidebar.nav-sidebar-client .nav-sidebar-part > a{
    display: none;
}
.sidebar .nav-sidebar.nav-sidebar-client .nav-sidebar-part:first-child .double-menu{
    display: block;
    left: 0;
} */
.sidebar .nav>li.nav-sidebar-part > a{
    padding: 7px 10px!important;
    font-weight: normal;
}
.sidebar .nav>li.nav-sidebar-part > a:before,
.sidebar .nav>li.nav-sidebar-part > a:after{
    display: none!important;
}
.sidebar .nav>li>a{
    background: transparent!important;
    color: #005594;
    font-weight: normal!important;
    font-size: 11px;
    position: relative;
    z-index: 1;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
    line-height: 1.1;
    text-align: left;
    position: relative;
    z-index: 3;
    border-bottom: 1px solid #eee;
    padding: 7px 10px!important;
    min-width: 170px;
    display: flex;
    justify-content: space-between;
}
.sidebar .nav>li>a:hover,
.sidebar .nav>li.active>a{
    color: #005594!important;
    background: #eee!important;
    font-weight: bold!important;
}

.sidebar .nav>li>.sub-menu-container-mob {
    position: relative;
}

.globalMenu-item-mob .sub-menu-block .stock-item__btn {
    padding-top: 0;
    padding-bottom: 0;
}

.sidebar .nav> .globalMenu-item-mob {
    display: none;
}

.sidebar .nav> .globalMenu-item-mob> a button {
    width: 29px;
    height: 29px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    font-size: 20px;
}

.sidebar .nav> .globalMenu-item-mob[data-name="wm"]> a button,
.sidebar .nav> .globalMenu-item-mob[data-name="wm-trial"]> a button {
    display: none;
}

.sidebar .nav> .globalMenu-item-mob> a .fa-angle-right {
    transform: rotate(90deg);
}

.sidebar .nav> .globalMenu-item-mob> a .fa-angle-right.active{
    transform: rotate(-90deg);
}

.topmenu-parent .search{
    margin: 0!important;
    left: auto!important;
    right: 180px!important;
    top: 3px!important;
    color: #333;
}
.topmenu-parent .search input{
    width: 200px!important;
    border: 0!important;
    background: #336aa0!important;
    color: #fff!important;
    -webkit-box-shadow: none;
            box-shadow: none;
    height: 25px!important;
    font-size: 12px!important;
    padding-left: 10px!important;
}
.topmenu-parent .search .search-btn{
    background: transparent!important;
    color: #fff!important;
    top: 3px!important;
    position: absolute!important;
}
.topmenu-parent .search .search-btn i{
    color: #fff!important;
}
.topmenu-parent .dropdown .btn.dropdown-toggle{
    padding: 0!important;
    color: #fff!important;
}
.profile-dropdown .btn-link.dropdown-toggle{
    font-size: 13px;
}
.profile-dropdown .dropdown-menu{
    border-radius: 0;
}
.profile-dropdown .dropdown-menu li a,
.profile-dropdown .dropdown-menu li span {
    color: #333;
    padding: 3px 20px;
    display: block;
    white-space: nowrap;
    line-height: 1.42857143;
}
.profile-dropdown .dropdown-menu li ul {
    list-style: none;
    padding-left: 0;
}
.profile-dropdown .dropdown-menu li ul a {
    list-style: none;
    padding-left: 30px;
}
.profile-dropdown .dropdown-menu li a:hover {
    background-color: #f5f5f5;
}
.topmenu-parent .asset-item__list-credit{
    width: auto;
    position: static;
    background: transparent;
    border: 0;
    display: block;
    text-transform: none;
    white-space: nowrap;
}
.topmenu-parent .asset-item__list-credit .asset-subtheme{
    display: block;
    padding: 0;
    background: transparent;
}
.topmenu-parent .asset-item__list-credit .asset-subtheme label{
    padding: 5px 10px;
    margin: 0;
    display: block;
    border-bottom: 1px solid #eee;
    background: transparent!important;
    color: #005594;
}
.topmenu-parent .asset-item__list-credit .asset-subtheme label:hover{
    color: #005594;
    background: #eee!important;
    font-weight: bold;
}
.topmenu-parent .asset-item__list-credit .asset-subtheme__radio-credit{
    display: none;
}
.topmenu-parent .asset-item__list-credit .asset-subtheme__radio-credit:checked + label{
    color: #005594;
    background: #eee!important;
    font-weight: bold;
}
.double-menu{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    z-index: 1;
    left: 100%;
    top: 0;
    display: none;
    background: #fff;
}
.sidebar .nav>li:hover .double-menu{
    display: block;
}
.nav-sidebar-submenu{
    display: block;
    background: #fff;
    padding: 0;
    white-space: nowrap;
    overflow: visible;
    border: 1px solid #eee;
    top: -1px;
    position: relative;
    -webkit-box-shadow: 2px 2px 5px rgba(0,0,0,.3);
            box-shadow: 2px 2px 5px rgba(0,0,0,.3);
}
.nav-sidebar-submenu[data-name='CUSTOM PAGES']{
    max-height: 500px;
    overflow: auto;
    overflow-x: hidden;
}
.nav-sidebar-submenu li,
.sidebar .nav>li{
    position: relative;
}
.nav-sidebar-submenu li .sub-menu-container{
    position: absolute;
    left: 100%;
    display: none;
    z-index: 1;
    background: #fff;
    border: 1px solid #eee;
    top: -1px;
    -webkit-box-shadow: 2px 2px 5px rgba(0,0,0,.3);
            box-shadow: 2px 2px 5px rgba(0,0,0,.3);
}
.nav-sidebar-submenu li:hover .sub-menu-container{
    display: block;
}

.nav-sidebar-submenu .stock-item{
    display: block;
    margin: 0;
}
.nav-sidebar-submenu .stock-item.country-item{
    width: 49%;
}
.nav-sidebar-submenu .stock-item a{
    padding: 5px 10px;
    border-bottom: 1px solid #eee;
    margin: 0;
    display: block;
    width: auto;
    border: 0;
    background: 0 0;
    height: auto;
    font-size: 13px!important;
    font-weight: 400;
    color: #005594;
    white-space: nowrap;
    border-bottom: 1px solid #eee;
}
.nav-sidebar-submenu .stock-item a:hover{
    text-decoration: none;
    color: #005594;
    font-weight: bold;
    background: #eee!important;
}

.nav-sidebar-submenu .stock-menu.country-menu{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 280px;
}

.backtest-wrapper{
    padding-top: 20px;
    min-width: 425px;
}
.sidebar-parent:hover .sidebar{
    display: block!important;
}

/*---error page---*/
.main-container.error-page{
    padding: 80px 0;
    text-align: center;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    background: #fff;
}
.error-page .error-header{
    margin-bottom: 120px;
}
.error-page .error-title{
    font-size: 60px;
    margin-bottom: 10px;
}
.error-page .error-text{
    font-size: 20px;
    color: #333;
    margin-bottom: 20px;
}
.error-page .error-btn .btn{
    border: 1px solid #005594;
    background: transparent;
    -webkit-box-shadow: none;
            box-shadow: none;
    color: #005594;
    font-weight: bold;
}
.error-page .error-btn .btn:hover{
    background: #eee;
}

/*---error page header---*/
.error-header header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    max-width: 1920px;
    width: 100%;
    height: 75px;
    padding-right: 3.1%;
    padding-left: 3.1%;
    background-color: #0D3866;
    -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, .1);
            box-shadow: 0 3px 5px rgba(0, 0, 0, .1);
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    position: fixed;
    top: 0;
    z-index: 11;
}

.error-header header a.logo {
    font-family: 'NunitoSans-Regular', sans-serif;
    font-size: 28px;
    color: #fff;
    margin-right: 50px;
}

.error-header header nav.lp-header-nav {
    display: none;
}

/*---error page footer---*/
footer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 100%;
    height: 50px;
    padding-right: 60px;
    padding-left: 60px;
    background-color: #0D3866;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    position: absolute;
    z-index: 1;
    bottom: 0;
    border-top: 1px solid #cbd4dd;
}

footer .copyright {
    font-size: 12px;
    color: #fff;
}

footer .copyright a {
    color: #fff;
}

footer .copyright p {
    margin: 0;
}

footer .telephone-email {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}
footer img {
    width: 100%;
}
footer .telephone-email a {
    font-size: 12px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    color: #fff;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    margin-right: 10px;
    margin-left: 10px;
}

footer .telephone-email a i {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 15px;
}

footer .telephone-email .footer-email i,
footer .telephone-email .footer-phone i {
    background-size: contain;
    filter: invert(1);

}

footer .social-networks {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 310px;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
            justify-content: flex-end;
}

footer .social-networks a {
    display: block;
    width: 20px;
    height: 20px;
    margin-right: 5px;
    margin-left: 5px;
    background-size: contain;
}

footer .social-networks-facebook {
    position: relative;
    top: -2px;
}

@media screen and (max-width: 1050px){
    footer {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
        padding-top: 20px;
        padding-bottom: 20px;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        height: auto;
    }

    footer .social-networks {
        -webkit-box-pack: center;
        -webkit-justify-content: center;
            -ms-flex-pack: center;
                justify-content: center;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    footer .copyright {
        -webkit-box-ordinal-group: 4;
        -webkit-order: 3;
            -ms-flex-order: 3;
                order: 3;
    }
}

@media screen and (max-width: 450px){
    footer {
        padding-right: 5px;
        padding-left: 5px;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    footer .social-networks {
        margin-top: 5px;
        margin-bottom: 5px;
    }
}


/* srollbar style*/
.top-menu ::-webkit-scrollbar,
.scrolled-block::-webkit-scrollbar{
  width: 10px;
}
.top-menu ::-webkit-scrollbar-track,
.scrolled-block::-webkit-scrollbar-track{
  -webkit-box-shadow: inset 0 0 2px 2px #ddd;
          box-shadow: inset 0 0 2px 2px #ddd;
}
.top-menu ::-webkit-scrollbar-thumb,
.scrolled-block::-webkit-scrollbar-thumb{
  background: #ddd;
}
.top-menu::-webkit-scrollbar-thumb:hover,
.scrolled-block::-webkit-scrollbar-thumb:hover{
  background: #ccc;
}


/*--lineCharts--*/
.line_charts .axis line,
.line_charts .axis path {
    opacity: 0.3!important;
  }
 .line_charts .line_charts_circle.active {
    stroke-opacity: 1!important;
    stroke: rgba(255,255,0,1)!important;
}
.bulletTooltip {
    position: absolute;
    z-index: 11;
    color: #333;
    font-size: 11px;
    font-weight: normal;
    background: #fff;
    padding: 2px 5px;
    border: 2px solid #333;
    border-radius: 5px;
    text-align: center;
    white-space: break-spaces;
    z-index: 1000;
}

@media screen and (max-width: 960px){
    .top-menu .globalMenu-item .menu-item {
        display: none;
    }
    #bloomberg-container .top-menu .globalMenu-item .menu-item {
        display: inline-block;
    }

    .top-menu {
        height: 30px;
    }

    .sidebar .nav> .globalMenu-item-mob {
        display: block;
    }
}

@media screen and (max-width: 450px){
    .error-page .error-title {
        font-size: 42px;
    }
}

.loading-page-spiner.loader-spiner-parent{
    position: fixed;
    left: 0;
    top: 33px;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 100;
}
.loading-page-spiner.loader-spiner-parent .loader-spiner{
    display: flex;
    justify-content: center;
    margin-top: -25px;        
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -25px;        
}
.main-container .bootstrap-select a:focus,
.main-container .bootstrap-select .dropdown-toggle:focus,
.main-container .bootstrap-select .dropdown-toggle:active{
    outline: 0!important;
    background-color: #fff!important;
}
.main-container .bootstrap-select a:focus{
    background-color: inherit!important;
}
.main-container .bootstrap-select li.selected{
    background: #efefef;
}
.main-container .dropdown-menu>li>a:focus{
    background-color: inherit;
}

.main-container .btn.expand-btn,
.main-container .btn.exp-btn{
    outline: 0!important;
}

.center {
    display: flex;
    justify-content: center;
}
