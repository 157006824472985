.alertify-notifier div.ajs-message.ajs-visible {
    text-shadow: none;
    width: 300px;
    text-align: left;
    border: none;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .2);
    border-radius: 1px;
}

.alertify-notifier.ajs-right div.ajs-message.ajs-visible {
    right: 300px;
}