/*
 * Base structure
 */

/* Move down content because we have a fixed navbar that is 50px tall */
body {
    background-color: #f1f1f1;
}

/*
 * Global add-ons
 */

.sub-header {
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
}

/*
 * Top navigation
 * Hide default border to remove 1px line.
 */
.navbar-fixed-top {
    border: 0;
}
.profile-dropdown{
    right: 5px;
    top: 2px;
    position: absolute;
}
.profile-dropdown > .btn{
    color: #fff;
}
.profile-dropdown:after {
    display: block;
    content: '';
    clear: both;
}

/*
 * Sidebar
 */

/* Hide for mobile, show later */
.sidebar {
    display: none;
}
.navbar-mobile {
    display: block;
}

@media (min-width: 768px) {
    .sidebar {
        position: fixed;
        top: 0px;
        bottom: 0;
        left: 0;
        width: 110px;
        z-index: 1000;
       /* display: block;*/
        padding: 5px;
        overflow-x: hidden;
        overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
        background-color: #222222;
        /*border-right: 1px solid #eee;*/
    }
    .navbar-mobile {
        display: none;
    }
    .sidebar .nav > li > a {
        padding: 5px 15px 5px 17px;
    }

    .nav-sidebar .nav-sidebar-part:nth-child(2) .nav-sidebar-submenu li a,
    .nav-sidebar .nav-sidebar-part:nth-child(3) .nav-sidebar-submenu li a {
        padding-top: 3px;
        padding-bottom: 3px;
    }
}

@media (max-width: 768px) {
    .sidebar {
        display: none;
        background-color: #222222 !important;
    }
    .navbar-mobile {
        display: none;
    }
    .sidebar .nav > li > a {
        padding: 5px 15px 5px 17px;
    }
    .nav-sidebar {
        margin-left: inherit !important;
        margin-right: inherit !important;
    }
}

/* Sidebar navigation */
.nav-sidebar {
    margin-right: -21px; /* 20px padding + 1px border */
    margin-bottom: 20px;
    margin-left: -20px;
    font-size: 12px;
}
.nav-sidebar > li > a,
.nav-sidebar-submenu > li > a{
    padding-right: 20px;
    padding-left: 20px;
    color: #fff;
}

.nav-sidebar-submenu > li > a {
    color: #bbb;
}

.nav-sidebar-submenu {
    background-color: #333333;
    display: none;
    padding-left: 10px;
}

.nav-sidebar > .active .nav-sidebar-submenu {
    display: block;
}

.nav-sidebar li a:hover,
.nav-sidebar li a:focus {
    background-color: #111111;
    color: #45bbe6;
}

.nav-sidebar-submenu li a:hover,
.nav-sidebar-submenu li a:focus {
    background-color: inherit;
}

.nav-sidebar > .active > a,
.nav-sidebar > .active > a:hover,
.nav-sidebar > .active > a:focus {
    color: #fff;
    background-color: #0074A2;
}

.nav-sidebar-submenu > .active > a,
.nav-sidebar-submenu > .active > a:hover,
.nav-sidebar-submenu > .active > a:focus {
    color: #fff;
    font-weight: bold;
}

.nav-sidebar > .active  > a:after {
    right: 0;
    border: 8px solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-right-color: #f1f1f1;
    top: 50%;
    margin-top: -8px;
}


/*
 * Main content
 */

@media (min-width: 768px) {
    .main {
        padding: 0;
        margin: 0;
    }
}
.main .page-header {
    margin-top: 0;
}


/*
 * Placeholder dashboard ideas
 */

.placeholders {
    margin-bottom: 30px;
    text-align: center;
}
.placeholders h4 {
    margin-bottom: 0;
}
.placeholder {
    margin-bottom: 20px;
}
.placeholder img {
    display: inline-block;
    border-radius: 50%;
}